"use strict";


const baseURL = "https://liprincipaldashboard-56258-ruby.56258.dev.ap-southeast-1.aws.svc.builder.cafe";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
